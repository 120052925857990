import { type FC, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { Button, EmailInput, Spinner } from '@lib/components'
import { subscribe, Products } from '@shamaazi/mytennights'

interface FormData {
  email: string
  mytennights: boolean
}

export const EmailSubscriber: FC = () => {
  const [submitted, setSubmitted] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({ defaultValues: { email: '' } })

  const queryClient = useQueryClient()
  const { isLoading, isError, mutate: onSubscribe } = useMutation(async (data: FormData): Promise<void> => {
    const products = [Products.myTenDays]
    if (data.mytennights) {
      products.push(Products.myTenNights)
    }
    await subscribe(data?.email ?? '', products)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('preferences').catch(() => { })
      setSubmitted(true)
    }
  })

  if (submitted) {
    return <p className="my-6 text-lg font-medium text-mtd-blue-800">Thank you for subscribing!</p>
  }

  return <>
    {isLoading && <Spinner className="mx-auto mt-4 text-mtd-blue" />}
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    {!isLoading && <form className="flex flex-col sm:w-2/3" onSubmit={handleSubmit((data) => onSubscribe(data))}>
      <div className="flex flex-wrap gap-y-4 gap-x-4 mt-4">
        <div className="flex flex-col flex-grow">
          <EmailInput variant="mtd" register={register} errors={errors} />
          <p
            className="mt-1 text-xs text-right text-mtd-red min-h-xs">{errors.email?.message ?? ''}{isError && 'Sorry! Something went wrong subscribing you!'}</p>
        </div>
        <div className="flex flex-col">
          <Button
            variant="mtd-primary"
            disabled={isLoading}
            loading={isLoading}
          >
            REMIND ME
          </Button>
          <div className="min-h-xs">{/* hacky spacer */}</div>
        </div>
      </div>
      <label className="inline row stack">
        <input
          className="mt-1"
          type="checkbox"
          {...register('mytennights')}
        />
        <p className="ml-4">Also remind me to catch Laylatul Qadr on <a target="_blank" rel="noopener noreferrer" href="https://mytennights.com">My Ten
          Nights</a></p>
      </label>
    </form>}
  </>
}
