import { useHistory } from 'react-router-dom'
import { type FC } from 'react'
import { type Cause, DafDisclaimer, getMinimumAmount, isMultiCharity, useCheckoutPlan, ProductName, MTDtracking, calculateDailyAmounts, getFirstDay } from '@shamaazi/mytennights'
import { Currency, displayCurrency } from '@lib/services'

import { CheckoutProgress, CheckoutStep } from '~/components/CheckoutProgress'
import CauseCard from '~/components/CauseCard'
import { MytennightsTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'

export const Causes: FC<{ nextPage: string }> = ({ nextPage }) => {
  const { plan, setPlan } = useCheckoutPlan()
  const { user } = useAuth()
  const history = useHistory()

  const onCauseChange = (cause: Cause, value: number): void => {
    setPlan({ ...plan, causes: { ...plan.causes, [cause.cause_id]: value } })
  }

  const causeCards = (plan?.charity?.causes ?? []).map((c: Cause) => {
    const onChange = (v: number): void => onCauseChange(c, v)
    const value = plan.causes[c.cause_id]
    return <CauseCard
      key={c.cause_id}
      cause={c}
      plan={plan}
      value={value}
      onChange={onChange}
      trackEvent={() => {
        MTDtracking.addCause(user?.email, c.title, value, plan)
      }
      }/>
  })

  const onSubmit = (): void => {
    MTDtracking.confirmAmount(user?.email, plan)
    setPlan({ ...plan, firstNight: getFirstDay(plan.startDateOffset) })
    history.push(nextPage)
  }

  const causes: Record<string, number> = plan.causes
  const totalAmount = Object.values(causes).reduce((a, b) => a + b, 0)
  const { dailyAmount } = calculateDailyAmounts(plan.split, plan.multiplier, plan.startDateOffset, totalAmount)
  const minAmount = getMinimumAmount(plan?.charity?.currency ?? Currency.GBP)
  const invalid = dailyAmount < minAmount
  const hasInput = Object.values(causes).some(v => v > 0)

  const multiCharity = isMultiCharity(plan.charity.charity_id)

  return <main>
    <CheckoutProgress currentStep={CheckoutStep.Causes} />
    <div className="max-w-4xl px-4 mx-auto my-6 column stack">
      <h1 className="text-3xl font-bold text-mtd-blue-900">Choose causes</h1>
      {multiCharity && <>
        <p className="my-4 font-medium">The MyTenDays Fund will spread your donation across multiple charities.</p>
      </>}
      <DafDisclaimer country={plan.charity.country} product={ProductName.myTenDays}/>
      {causeCards}
      <div className="justify-end row">
        <button
          data-test-id={MytennightsTestId.continueButton}
          className="btn" disabled={!hasInput || invalid} onClick={() => onSubmit()}>CONTINUE
        </button>
      </div>
      {invalid && hasInput
        ? <div className="input-error">Your daily amount falls below the minimum of {displayCurrency({ amount: minAmount, currency: plan.charity.currency })}</div>
        : <></>
      }
    </div>
  </main>
}
