import { type FC } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { ActiveCampaign, loadCharityCampaign, NotFoundError, useCheckoutPlan, MTDtracking } from '@shamaazi/mytennights'

import Charity from '~/components/Charity'

const CharityPage: FC<{ nextPage: string }> = ({ nextPage }) => {
  const { setCharity } = useCheckoutPlan()
  const { charityID } = useParams<{ charityID: string }>()
  const history = useHistory()
  const queryParams = new URLSearchParams(useLocation().search)
  const userId = queryParams.get('user_id')

  const { data: charity, isLoading, isError, error } = useQuery(
    ['charity', charityID],
    async () => loadCharityCampaign(ActiveCampaign.myTenDays, charityID),
    {
      onSuccess: (data) => {
        MTDtracking.setPartnerCharitySite(data.charity_name, data.charity_id, userId)
        setCharity(data)
      },
      retry: false,
      onError: () => history.push('/')
    }
  )

  return <>
    <Charity
      loading={isLoading}
      isError={isError}
      notFound={error instanceof NotFoundError}
      charity={charity}
      nextPage={nextPage}
    />
  </>
}

export default CharityPage
