import { Route, type RouteProps } from 'react-router-dom'
import { useAuth } from '@lib/hooks'
import { LoginPage } from '~/pages/Login'
import { type FC } from 'react'

const AuthenticatedRoute: FC<RouteProps> = ({ children, ...routeArgs }) => {
  const { user } = useAuth()
  return <Route {...routeArgs}>
    {user ? children : <LoginPage />}
  </Route>
}

export default AuthenticatedRoute
