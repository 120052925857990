import { type FC } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ProvideAuth } from '@lib/hooks'
import { PageViewEvents, ScrollToTop } from '@lib/components'
import { ActiveCampaign, AddressLookupStore, CheckoutPlanProvider, PaymentContainer, MTDTracking } from '@shamaazi/mytennights'

import { CookieBanner } from '~/components'
import { Contact, CookiePreferences, Home, Payment, Preferences, Schedule, ScheduleList, SchedulePayment } from '~/pages'
import { Charities } from '~/pages/Charities'
import { Causes } from '~/pages/Causes'
import Charity from '~/pages/Charity'
import { Share } from '~/pages/Share'
import PrivacyPolicy from '~/pages/PrivacyPolicy'
import TermsAndConditions from './pages/TermsAndConditions'
import FAQs from './pages/FAQs'
import { FooterLayout } from '~/components/Footer'
import AuthenticatedRoute from '~/components/AuthenticatedRoute'

import { CausesRedirect, CharityRedirect, HomeRedirect } from '~/CheckoutRedirects'
import { browserExceptions, MixpanelInstance } from '@lib/services'
import { Navigation } from '~/navigation/navigation'
import ShareOptions from './pages/ShareOptions'

void browserExceptions.initialize()

const queryClient = new QueryClient()

const tracking = new MTDTracking()
tracking.initialize()

const addressLookupStore = new AddressLookupStore()

export const App: FC = () => {
  return <ProvideAuth campaignQueryParameter={ActiveCampaign.myTenDays} onSetUser={(u) => MixpanelInstance.tryIdentify(u?.email)}>
    <QueryClientProvider client={queryClient}>
      <CheckoutPlanProvider>
        <Router>
          <ScrollToTop />
          <PageViewEvents tracking={tracking} trackable={tracking.isTrackableUrl} />
          <FooterLayout>
            <Switch>
              <Route exact path={Navigation.home()}>
                <Home nextPage={Navigation.selectCharity()} />
              </Route>
              <Route exact path={Navigation.selectCharity()}>
                <HomeRedirect />
                <Charities nextPage={Navigation.selectCauses()} />
              </Route>
              <Route exact path={Navigation.selectCauses()}>
                <CharityRedirect />
                <Causes nextPage={Navigation.payment()} />
              </Route>
              <Route exact path={Navigation.payment()}>
                <CausesRedirect />
                <PaymentContainer campaign="MyTenDays">
                  <Payment addressLookupStore={addressLookupStore} />
                </PaymentContainer>
              </Route>
              <Route exact path="/share">
                <Share />
              </Route>
              <Route exact path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route exact path="/terms-and-conditions">
                <TermsAndConditions />
              </Route>
              <AuthenticatedRoute exact path="/account/schedules">
                <ScheduleList />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/account/schedule/:scheduleID/payment/:number">
                <PaymentContainer campaign="MyTenDays">
                  <SchedulePayment />
                </PaymentContainer>
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/account/schedule/:scheduleID">
                <Schedule />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/account/preferences">
                <Preferences />
              </AuthenticatedRoute>
              <Route exact path="/cookie-preferences">
                <CookiePreferences />
              </Route>
              <Route exact path="/faqs">
                <FAQs />
              </Route>
              <Route exact path="/contact">
                <Contact />
              </Route>
              <Route exact path="/:charityID">
                <Charity nextPage={Navigation.selectCauses()} />
              </Route>
              <Route exact path="/share/:shareCode">
                <ShareOptions />
              </Route>
              <Route exact path="/link/:chainCode">
                <Home nextPage={Navigation.selectCharity()} />
              </Route>
              <Route>
                <h1 className="heading-xl">This page could not be found</h1>
              </Route>
            </Switch>
          </FooterLayout>
          <CookieBanner />
        </Router>
      </CheckoutPlanProvider>
    </QueryClientProvider>
  </ProvideAuth>
}
